// featuredCourse style

.featuredCourseWrap {
  padding   : 90px 0 70px;
  background: $grayBg;

  &.otherCourseWrap {
    background : $wColor;
    padding-top: 0;
  }
}


.featContent {
  position     : relative;
  margin-bottom: 13px;

  .secTopTitle {}

  .secTitle {}

  .arrowBtn {
    display : flex;
    position: absolute;
    right   : 0;
    bottom  : 10px;

    button {
      display        : flex;
      align-items    : center;
      justify-content: center;
      width          : 30px;
      height         : 30px;
      background     : transparent;
      border         : 1px solid $dark;
      @include boxs(none);
      outline: none;
    }
  }
}

.featuredCourseSlider {
  .slick-arrow {
    width  : 40px;
    height : 40px;
    z-index: 1;
    @include br(50%);
    @include ts(all 0.5s);

    &.slick-disabled {
      opacity   : 0;
      visibility: hidden;
    }

    &::before {
      font-family    : 'Flaticon';
      font-size      : 20px;
      display        : flex;
      align-items    : center;
      justify-content: center;
      width          : 100%;
      height         : 100%;
      background     : $brandColor1;
      @include br(50%);
      @include ts(all 0.5s);
      @include boxs(0px 0px 10px 0px rgba(0, 0, 0, 0.3));

    }

    &:hover {
      &::before {
        @include tf(scale(1.2));
      }
    }

    &.slick-prev {
      left: 0;

      &::before {
        content: "\f101";
      }
    }

    &.slick-next {
      right: 0;

      &::before {
        content: "\f100";
      }
    }
  }

  .featuredCourseSliderItem {
    @include boxs(none);
    outline: none;
    margin : 30px 0;
  }
}


.featuredSingleItem {
  background: $wColor;
  @include boxs(0px 0px 15px 0px #cbcbcb);
  @include br(30px);
  display      : inline-block;
  overflow     : hidden;
  margin-bottom: 30px;
  width        : 100%;

  .courseThamImg {
    border-bottom: 2px solid $brandColor1;

    img {
      width: 100%;
    }
  }

  .featuredLogo {
    border-style    : solid;
    border-width    : 2px;
    border-color    : rgb(255, 255, 255);
    border-radius   : 50%;
    background-color: rgb(255, 255, 255);
    @include boxs(-0.07px -1.999px 24px 0px rgba(118, 118, 118, 0.74));
    width          : 89px;
    height         : 89px;
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin         : -45px auto 23px;

    img {
      @include ts(all 0.5s);
    }

  }

  .featuredContent {
    padding-bottom: 25px;

    .teachersName {
      color        : $fetColor;
      font-size    : $f16;
      font-family  : $open;
      line-height  : 25px;
      margin-bottom: 5px;
    }

    .courseName {
      color             : $dark;
      font-size         : $f16;
      font-family       : $open;
      line-height       : 25px;
      font-weight       : 700;
      padding           : 0 20px;
      overflow          : hidden;
      text-overflow     : ellipsis;
      display           : -webkit-box;
      max-height        : 50px;
      min-height        : 50px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .FeaturedCourseProgress {
    padding: 0 20px;
  }

  .featuredCourseFooter {
    background: $grayBg;
    padding   : 15px 0;
    position  : relative;

    &::before {
      position  : absolute;
      content   : "";
      left      : 0;
      bottom    : 0;
      width     : 50%;
      height    : 10px;
      background: $brandColor1;
    }

    &::after {
      position  : absolute;
      content   : "";
      right     : 0;
      bottom    : 0;
      width     : 50%;
      height    : 10px;
      background: $brandColor2;
    }

    .courTimeList {
      margin    : 0;
      padding   : 0 45px;
      list-style: none;

      li {
        color          : $fetColor;
        font-size      : $f14;
        line-height    : 18px;
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        padding        : 0 0 10px;
        margin-bottom  : 10px;
        border-bottom  : 1px solid #ddd;

        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
        }

        span {
          &:first-child {

            text-transform: uppercase;
          }
        }
      }
    }
  }

  &:hover {
    .featuredLogo {
      img {
        @include tf(scale(1.05));
      }
    }
  }
}

.FeaturedInnerItem,
.digitalQuarnItem {
  padding: 190px 0 135px;

  .featuredTop {
    margin-bottom: 60px;

    .secTitle3 {}

    .fePara {
      max-width: 660px;
      width    : 100%;
      margin   : 0 auto;
    }
  }
}

.largestCoursesWrap {
  padding: 210px 0 135px;

  .largestCourseTop {
    .largecoTitle {
      font-size: $f20;
      color    : $dark;
    }

    .largecoPara {
      font-size: $f14;
      color    : $dark;
    }
  }

  .pricTAb {
    justify-content: center;
    border-bottom  : 1px solid #b9b9b9;

    .nav-item {
      border     : none;
      margin     : 0;
      font-size  : $f14;
      font-weight: 600;
      color      : $dark;
      position   : relative;
      padding    : 20px 10px;

      &+.nav-item {
        margin-left: 30px;
      }

      &::before {
        position  : absolute;
        content   : "";
        bottom    : 0;
        left      : 0;
        right     : 0;
        margin    : 0 auto;
        width     : 0;
        height    : 4px;
        background: $brandColor1;
        @include op(0);
        @include ts(all 0.5s);
        @include br(2px);
      }

      &.active {
        color: $brandColor1;

        &::before {
          width: 100%;
          @include op(1);

        }
      }
    }
  }

  .tab-content {
    height: 500px;
    .tab-pane {
      @include tf(scale(1));
      @include ts(all 0.5s);

      &:not(.show) {
        @include tf(scale(0.9));
      }
    }
  }
}

.SingleItem {
  margin-bottom: 30px;
  height       : 100%;
}

.feSingleItem {
  display      : inline-block;
  background   : $wColor;
  border-radius: 35px;
  @include boxs(0 0 10px 10px rgba(0, 0, 0, 0.05));
  padding: 25px 30px;
  @include ts(all 0.5s);
  height: 100%;
  width: 100%;

  &:hover {
    .cont {
      .fetitle {
        color: $wColor;
      }

      .fepara {
        color: $wColor;
      }
    }
  }

  .itemIcon {
    margin-bottom: 20px;

    svg {
      max-width: 40px;
      width    : 100%;
      fill     : $brandColor1;
    }
  }

  .cont {
    .fetitle {
      font-family  : $open;
      font-weight  : 600;
      font-size    : $f20;
      line-height  : 28px;
      margin-bottom: 12px;
      color        : $dark;
    }

    .fepara {
      font-family       : $open;
      font-weight       : 400;
      font-size         : $f14;
      line-height       : 25px;
      color             : $dark;
      overflow          : hidden;
      text-overflow     : ellipsis;
      display           : -webkit-box;
      max-height        : 50px;
      min-height        : 50px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &.oneToOne {
    .itemIcon {
      svg {
        fill: #7029e6;
      }
    }

    &:hover {
      background: #7029e6;

      .itemIcon {
        svg {
          fill: $wColor;
        }
      }
    }
  }

  &.group {
    .itemIcon {
      svg {
        fill: #2986e6;
      }
    }

    &:hover {
      background: #2986e6;

      .itemIcon {
        svg {
          fill: $wColor;
        }
      }
    }
  }

  &.quiz {
    .itemIcon {
      svg {
        fill: #05ccd0;
      }
    }

    &:hover {
      background: #05ccd0;

      .itemIcon {
        svg {
          fill: $wColor;
        }
      }
    }
  }

  &.exam {
    .itemIcon {
      svg {
        fill: #00bbf1;
      }
    }

    &:hover {
      background: #00bbf1;

      .itemIcon {
        svg {
          fill: $wColor;
        }
      }
    }
  }

  &.Course_material {
    .itemIcon {
      svg {
        fill: #7029e6;
      }
    }

    &:hover {
      background: #7029e6;

      .itemIcon {
        svg {
          fill: $wColor;
        }
      }
    }
  }

  &.Digital_Quran {
    .itemIcon {
      svg {
        fill: #1957be;
      }
    }

    &:hover {
      background: #1957be;

      .itemIcon {
        svg {
          fill: $wColor;
        }
      }
    }
  }

  &.Namaj_Learning {
    .itemIcon {
      svg {
        fill: #1abcee;
      }
    }

    &:hover {
      background: #1abcee;

      .itemIcon {
        svg {
          fill: $wColor;
        }
      }
    }
  }

  &.Health_learning {
    .itemIcon {
      svg {
        fill: #11cd9f;
      }
    }

    &:hover {
      background: #11cd9f;

      .itemIcon {
        svg {
          fill: $wColor;
        }
      }
    }
  }

  &.Hajj_content {
    .itemIcon {
      svg {
        fill: #4fb3a8;
      }
    }

    &:hover {
      background: #4fb3a8;

      .itemIcon {
        svg {
          fill: $wColor;
        }
      }
    }
  }

  &.Islamic_Story {
    .itemIcon {
      svg {
        fill: #761bc4;
      }
    }

    &:hover {
      background: #761bc4;

      .itemIcon {
        svg {
          fill: $wColor;
        }
      }
    }
  }
}

.digitalQuranPageWrap {
  .feSingleItem {
    .itemIcon {
      svg {
        max-width: 70px;
      }
    }
  }
}

// featuredCourse style