.CourseDetailsWrap {
    position   : relative;
    z-index    : 1;
    padding-top: 42px;

    &::before {
        position: absolute;
        content : "";
        top     : 0;
        left    : 0;
        width   : 100%;
        height  : 140px;
        @include bgGradient ($brandColor1, $brandColor2);
        z-index: -1;
    }

    .CourseDetailsInner {
        @include boxs (-0.07px -1.999px 24px 0px rgba(118, 118, 118, 0.43));
        padding: 15px;
        @include br (35px);
        margin-bottom: 65px;
        background   : $wColor;
        position: relative;
    }


    .courseImgSliderContent {
        @include br (35px);
        overflow: hidden;
    }

    .courseDetailsRight {
        .playListWrap {
            -webkit-overflow-scrolling: auto;
            overflow-x                : hidden;
            overflow-y                : auto;
            scroll-behavior           : auto;
            flex-basis                : 0px;
            flex-grow                 : 1;
            flex-shrink               : 0;
            margin                    : 0;
            padding                   : 10px;
            position                  : relative;
            height                    : 100%;
            max-height                : 400px;


            &::-webkit-scrollbar-track {
                box-shadow        : none;
                -webkit-box-shadow: none;
                background-color  : transparent;
                border-radius     : 10px;
            }

            &::-webkit-scrollbar {
                width           : 10px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-image: -webkit-gradient(linear,
                        40% 0%,
                        75% 84%,
                        from($brandColor2),
                        to($brandColor2),
                        color-stop(.6, $brandColor1));
            }


            .plauList {
                margin    : 0;
                padding   : 0;
                list-style: none;

                li {
                    width      : 100%;
                    display    : flex;
                    align-items: center;
                    background : $wColor;
                    @include boxs (0px 0px 10px 0px rgba(0, 0, 0, 0.2));
                    margin-bottom: 25px;
                    padding      : 10px;
                    @include br (15px);
                    cursor: pointer;

                    .ago {
                        width : 45px;
                        height: 45px;
                        @include br (50%);
                        display        : flex;
                        align-items    : center;
                        justify-content: center;
                        background     : $brandColor1;
                        color          : $wColor;
                        margin-right   : 10px;

                        .agoText {
                            width     : 100%;
                            text-align: center;

                            span {
                                display    : block;
                                line-height: 12px;
                            }

                            .wCount {
                                font-size    : $f20;
                                margin-bottom: 2px;
                            }

                            .text {
                                font-size     : $f10;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .moduleName {
                        width   : 70%;
                        position: relative;

                        &::before {
                            position   : absolute;
                            content    : attr(dataTitle);
                            top        : 100%;
                            left       : 0;
                            background : $dark;
                            color      : $wColor;
                            padding    : 10px 15px;
                            font-size  : $f12;
                            line-height: 18px;
                            @include br (15px);
                            @include ts (all 0.5s);
                            @include op (0);
                            visibility: hidden;
                            display   : none;
                        }

                        &::after {
                            position     : absolute;
                            content      : "";
                            top          : 100%;
                            left         : 0;
                            right        : 0;
                            width        : 0;
                            height       : 0;
                            margin       : -10px auto 0;
                            border-bottom: 10px solid $dark;
                            border-right : 10px solid transparent;
                            border-left  : 10px solid transparent;
                            @include ts (all 0.5s);
                            @include op (0);
                            visibility: hidden;
                            display   : none;
                        }

                        p {
                            font-size         : $f14;
                            color             : $dark;
                            overflow          : hidden;
                            text-overflow     : ellipsis;
                            display           : -webkit-box;
                            max-height        : 25px;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            word-break        : break-all;
                        }
                    }

                    &.active {
                        background: $brandColor2;

                        .ago {
                            background: $wColor;
                            color     : $brandColor2;
                        }

                        .moduleName {
                            p {
                                color: $wColor;
                            }
                        }
                    }

                    &:hover {
                        .moduleName {

                            &::before,
                            &::after {
                                @include op (1);
                                visibility: visible;
                            }
                        }
                    }
                }

                .card-header {
                    padding         : 0;
                    margin-bottom   : 0;
                    background-color: transparent;
                    border-bottom   : none;

                    .rightIcon {
                        font-size: 24px;
                    }

                    .active {
                        .rightIcon {
                            color: $wColor;
                        }
                    }
                }

                .card-body {
                    padding-top: 0;

                    .MaterialList {
                        padding-left: 15px;

                        .MaterialListItem {
                            font-size: $f16;
                            color    : $dark;
                            @include ts(0.5s);
                            margin-bottom: 20px;
                            cursor       : pointer;
                            font-weight  : 600;

                            &:hover {
                                color: $brandColor2;
                            }
                        }
                    }
                }
            }
        }
    }
}



.courseDetailsLeft {
    padding-right: 0;
}

.courseDteailsBannerWrap {
    &::before {
        height: 180px;
    }
}

.courseDteailsBannerContent {
    margin-bottom: 85px;

    .courseDetailsBannerTop {
        margin-bottom: 92px;

        .courseName {
            color             : $wColor;
            line-height       : 45px;
            overflow          : hidden;
            text-overflow     : ellipsis;
            display           : -webkit-box;
            max-height        : 90px;
            min-height        : 90px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .courseShortDiscription {
            color    : $wColor;
            font-size: $f16;
            max-width: 575px;
        }
    }

    .aboutCourse {
        margin-bottom: 50px;

        ul {
            padding   : 0;
            list-style: none;
            margin    : 0 0 20px;
        }

        p {
            margin-bottom: 20px;
        }

        .aboutTitle {
            font-size    : $f24;
            font-family  : $pnr;
            color        : $courseColor;
            margin-bottom: 13px;
        }

        .aboutPara {}

        .offeredLogo {
            .logoImg {
                margin-bottom: 10px;

                img {
                    @include boxs (0px 0px 20px 0px rgba(95, 95, 95, 0.4));
                    width : 95px;
                    height: 95px;
                    @include br (50%);
                }
            }

            p {
                color      : $dark;
                font-family: $open;
            }
        }
    }

    .whyChoseCourse {
        background: $borderColor;
        padding   : 60px 65px 15px;

        .whyTitle {
            font-size    : $f24;
            font-family  : $pnr;
            color        : $courseColor;
            margin-bottom: 40px;
        }

        .whyChoseSingleItem {
            margin-bottom: 45px;

            .itemTop {
                margin-bottom: 12px;

                h5 {
                    font-size  : $f18;
                    color      : $dark;
                    display    : flex;
                    align-items: center;

                    .icon {
                        margin-right: 15px;

                        svg {}
                    }
                }
            }

            .itemDetails {
                p {
                    font-size: $f14;
                    color    : $dark;
                }
            }

        }
    }
}

.coursePriceRightContent {
    .courseThumbnail {
        img {}
    }

    .coursePriceContent {
        @include boxs (0px 2px 10px 0px rgba(0, 0, 0, 0.2));
        padding: 30px 35px 45px;

        .coursePrice {
            .price {
                margin-bottom: 20px;

                .opperPrice {
                    font-family: $open;
                    font-size  : $f45;
                    font-weight: 700;
                    color      : $brandColor1;
                }

                .previusPrice {
                    font-family: $open;
                    font-size  : $f16;
                    font-weight: 400;
                    color      : $grayColor;
                    display    : inline-block;
                    margin-left: 10px;

                    del {
                        margin-right: 5px;
                    }
                }
            }

            .courseIncludes {
                padding-bottom: 30px;

                h5 {
                    color        : #2c2c2c;
                    font-size    : $f16;
                    margin-bottom: 23px;
                }

                .courseIncludesList {
                    margin    : 0;
                    padding   : 0;
                    list-style: none;

                    li {
                        font-size    : $f14;
                        line-height  : 18px;
                        position     : relative;
                        padding-left : 20px;
                        margin-bottom: 23px;

                        &::before {
                            position     : absolute;
                            content      : "";
                            left         : 0;
                            top          : 4px;
                            width        : 10px;
                            height       : 10px;
                            border-radius: 50%;
                            background   : #b4b4b4;
                        }
                    }
                }
            }

            .availableSlot {
                padding-bottom: 40px;

                h5 {
                    color        : #2c2c2c;
                    font-size    : $f16;
                    margin-bottom: 23px;
                }

                .form-check {
                    input[type="radio"] {
                        display: none;

                        &:checked {
                            &+label {
                                &::before {
                                    border: 5px solid $brandColor2;
                                }
                            }
                        }
                    }

                    label {
                        padding-left : 25px;
                        position     : relative;
                        margin-bottom: 10px;
                        cursor       : pointer;

                        &::before {
                            position     : absolute;
                            content      : "";
                            top          : 5px;
                            left         : 0;
                            width        : 16px;
                            height       : 16px;
                            border-radius: 50%;
                            border       : 1px solid $brandColor2;
                        }
                    }
                }

                .availableSlotSelect {
                    color     : $wColor;
                    background: $brandColor1;
                    height    : 26px;
                    @include br (13px);
                    @include boxs (none);
                    border     : none;
                    padding    : 0 15px;
                    line-height: 16px;
                    font-size  : $f14;
                    cursor     : pointer;
                }
            }

            .courseSkills {
                padding-bottom: 25px;

                h5 {
                    color        : #2c2c2c;
                    font-size    : $f16;
                    margin-bottom: 23px;
                }

                .skillsList {
                    margin    : 0;
                    padding   : 0;
                    list-style: none;

                    li {
                        display      : inline-block;
                        border       : 1px solid #aec4cd;
                        line-height  : 18px;
                        margin-bottom: 18px;
                        padding      : 5px 15px;
                        @include br (10px);
                        margin-right: 10px;
                        font-size   : $f12;
                        @include ts (all 0.5s);
                        cursor: pointer;

                        &:hover {
                            background: #aec4cd;
                        }
                    }
                }
            }

            .enroll {
                .enrollBtn {
                    max-width      : 225px;
                    width          : 100%;
                    margin         : 0 auto;
                    height         : 50px;
                    display        : flex;
                    align-items    : center;
                    justify-content: center;
                    border         : none;
                    color          : $wColor;
                    background     : $brandColor1;
                    @include br (25px);
                    @include ts (all 0.5s);

                    &:hover {
                        @include boxs (0px 10px 30px 0px rgba(23, 198, 122, 0.6));
                        background: $brandColor2;
                    }
                }
            }
        }
    }
}

.courseFaqWrap {
    max-width: 925px;
    width    : 100%;
    margin   : 0 auto 123px;

    .faqTop {
        max-width: 530px;
        width    : 100%;
        margin   : 0 auto 60px;

        .faqTitle {
            color        : #5b5b5b;
            font-size    : $f24;
            font-family  : $pnr;
            margin-bottom: 20px;
        }

        .faqPara {}
    }

    .courseFaq {
        .accordion {
            .card-header {
                background   : #eaeaea;
                border       : none;
                border-bottom: 2px solid $wColor;
                cursor       : pointer;
                padding-left : 40px;
                padding-right: 30px;

                .faqQuestion {
                    color      : #282828;
                    font-family: $pnr;
                    font-size  : $f18;
                    line-height: 20px;
                    width      : 100%;

                    .qNumber {
                        color      : #000;
                        font-size  : $f16;
                        font-weight: 600;
                    }
                }
            }

            .card-body {
                .ansBody {
                    p {
                        color      : #4e4e4e;
                        font-size  : $f14;
                        line-height: 27px;
                    }
                }
            }
        }
    }
}

.hadithDetailsWrap {
    .CourseDetailsWrap {
        &::before {
            // display: none;
        }

        .hadithChapter {
            .selectHadithChapter {
                position  : relative;
                background: $brandColor2;
                @include br (15px);

                &::before {
                    position   : absolute;
                    content    : "\f100";
                    font-family: 'Flaticon';
                    color      : $wColor;
                    right      : 30px;
                    top        : 20px;
                    @include tf (rotate(90deg));
                }

                select {
                    cursor    : pointer;
                    background: transparent;
                    border    : none;
                    color     : $wColor;
                    @include ap (none);
                    font-family: $pnr;
                    font-size  : $f18;
                    line-height: 24px;
                    @include br (15px);
                    outline: none;
                    @include boxs (none);
                    height       : 65px;
                    margin-bottom: 30px;
                    padding      : 0 30px;

                    option {
                        color: $dark;
                    }
                }
            }
        }

        .courseDetailsLeft {
            .courseImgSliderContent {
                background   : $hadithBg;
                padding      : 30px 10px;
                margin-bottom: 15px;

                .hadithBody {
                    overflow-y                : scroll;
                    height                    : 400px;
                    padding                   : 0px 30px;
                    -webkit-overflow-scrolling: auto;
                    overflow-x                : hidden;
                    overflow-y                : auto;
                    scroll-behavior           : auto;
                    flex-basis                : 0px;
                    flex-grow                 : 1;
                    flex-shrink               : 0;

                    &::-webkit-scrollbar-track {
                        box-shadow        : none;
                        -webkit-box-shadow: none;
                        background-color  : transparent;
                        border-radius     : 10px;
                    }

                    &::-webkit-scrollbar {
                        width           : 10px;
                        background-color: transparent;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background-image: -webkit-gradient(linear,
                                40% 0%,
                                75% 84%,
                                from($brandColor2),
                                to($brandColor2),
                                color-stop(.6, $brandColor1));
                    }


                }
            }
        }

        .courseDetailsRight {
            .playListWrap {
                max-height: 450px;
            }
        }
    }
}