// fonts family
$open: 'Open Sans',
'sans-serif';
$pnb: 'proxima_novabold';
$pnr: 'proxima_novaregular';



//----a style----	

$a-text-decuration: none;
$a-color          : inherit;

//----a-hover style----	

$a-hover-color: inherit;


// colors
$brandColor1      : #17c67a;
$brandColor2      : #0394d7;
$bColor           : #0066b4;
$dark             : #333434;
$darkLight        : #535353;
$wColor           : #fff;
$overlayBg        : #212121;
$placeHolderColor : #aeaeae;
$grayBg           : #f9f9f9;
$digitalClassroom : #17c67a;
$digitalContent   : #2990ee;
$hajjContents     : #0dc7c7;
$islamicLifestyle : #6631d2;
$fetColor         : #7f7f7f;
$courseColor      : #575757;
$priceColor       : #129ab3;
$tabBgColor       : #1acfac;
$commaColor       : #defcf2;
$clientName       : #135585;
$borderColor      : #f5f5f5;
$fCopy            : #00537b;
$pricePageTableBg : #2dd089;
$grayColor        : #8f8f8f;
$hadithBg         : #f9f7f4;
$grayLightBg      : #f3f3f3;


// fonts size

$menuFont    : 14px;
$p           : 16px;
$fbannerTitle: 62px;
$f10         : 10px;
$f12         : 12px;
$f13         : 13px;
$f14         : 16px;
$f16         : 16px;
$f18         : 18px;
$f20         : 20px;
$f22         : 22px;
$f23         : 23px;
$f24         : 24px;
$f30         : 30px;
$f34         : 34px;
$f38         : 38px;
$f45         : 45px;
$f50         : 50px;
$f55         : 55px;
$f95         : 95px;
$f166        : 166px;