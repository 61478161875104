.tasbihContent {
    position : relative;
    max-width: 400px;
    width    : 100%;
    margin   : 0 auto;

    .tasbihImg {
        width: 200%;
        img {}
    }

    .tasbihCount {
        position       : absolute;
        background     : #0495d5;
        width          : 195px;
        height         : 90px;
        top            : 50px;
        left           : 0;
        right          : 0;
        margin         : 0 auto;
        display        : flex;
        align-items    : center;
        justify-content: flex-end;
        color          : #fff;
        padding        : 3px;
        font-size      : 66px;
        overflow: hidden;
    }

    .tasbihIncrementBtn {
        position       : absolute;
        top            : 233px;
        left           : 2px;
        right          : 0;
        margin         : 0 auto;
        width          : 140px;
        height         : 140px;
        background     : #16c57d;
        color          : #fff;
        border         : none;
        border-radius  : 50%;
        display        : flex;
        align-items    : center;
        justify-content: center;
        font-size      : 30px;
        font-weight    : 700;
        font-family    : sans-serif;
    }

    .tasbihReset {
        position       : absolute;
        top            : 160px;
        right          : 100px;
        margin         : 0 auto;
        width          : 50px;
        height         : 50px;
        background     : #fff;
        border         : none;
        border-radius  : 50%;
        display        : flex;
        align-items    : center;
        justify-content: center;
        font-size      : 30px;
        font-weight    : 700;
        font-family    : sans-serif;

        &:before {
            position : absolute;
            content  : "Reset";
            top      : 7px;
            padding: 5px;
            border:#000 1px solid;
            left     : -140px;
            color    : #000;
            font-size: 19px;
        }

        &:after {
            position     : absolute;
            content      : "";
            top          : 12px;
            left         : -24px;
            border-top   : 7px solid #ff000000;
            border-left  : 15px solid #fff;
            border-bottom: 7px solid #0ff0;
        }
    }
}