/*
  Flaticon icon font: Flaticon
  Creation date: 18/02/2020 11:31
  */

@font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.eot");
    src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
    url("./Flaticon.woff2") format("woff2"),
    url("./Flaticon.woff") format("woff"),
    url("./Flaticon.ttf") format("truetype"),
    url("./Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: "Flaticon";
        src: url("./Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-style: normal;
}

.flaticon-next:before {
    content: "\f100";
}

.flaticon-left-arrow:before {
    content: "\f101";
}