.quizWrap {}

.quizSingleBody {
    padding: 180px 0 150px;
}

.quizBody {
    .stepTitle {
        font-size    : $f30;
        line-height  : 36px;
        margin-bottom: 20px;
        font-family  : $pnr;
        color        : $dark;
    }
}

.step {
    margin-bottom: 15px;

    .stepsContent {
        display        : flex;
        align-items    : center;
        justify-content: center;

        .steps {
            display        : flex;
            align-items    : center;
            justify-content: center;
            padding        : 0;
            margin         : 0;
            list-style     : none;

            li {
                width : 50px;
                height: 10px;
                @include br (10px);
                background: $brandColor1;
                margin    : 0 5px;
                opacity   : 0.4;

                &.active {
                    opacity: 1;
                }
            }
        }

        .stepesCount {}
    }
}

.stepsPara {
    color        : $brandColor2;
    margin-bottom: 30px;
}

.quizItemWrap {
    .question {
        margin-bottom: 50px;
        width        : 100%;

        span {
            width          : 50px;
            height         : 50px;
            display        : flex;
            align-items    : center;
            justify-content: center;
            margin         : 0 auto;
            color          : $wColor;
            background     : -webkit-linear-gradient(120deg, $brandColor1, $brandColor2);
            background     : -moz-linear-gradient(120deg, $brandColor1, $brandColor2);
            background     : -ms-linear-gradient(120deg, $brandColor1, $brandColor2);
            background     : -o-linear-gradient(120deg, $brandColor1, $brandColor2);
            background     : linear-gradient(120deg, $brandColor1, $brandColor2);
            @include br (5px);
            cursor: pointer;

            i {
                font-size: 30px;
            }
        }
    }
}

.quiz {}

.quizItem {}

.selectquiz {
    margin-bottom: 30px;

    .quizInput {
        input[type="radio"] {
            display: none;

            &:checked+label {
                background: $brandColor2;
                color: $wColor;

                &::before {
                    border-width: 8px;
                    border-color: $wColor;
                }
            }
        }

        label {
            width          : 100%;
            text-align     : center;
            position       : relative;
            font-size      : 40px;
            display        : flex;
            align-items    : center;
            justify-content: center;
            padding        : 25px 0;
            cursor         : pointer;
            color          : $brandColor2;
            @include boxs (0 0 10px 1px rgba(0, 0, 0, 0.05));
            @include br (20px);

            &::before {
                position: absolute;
                content : "";
                top     : 50%;
                @include tf (translateY(-50%));
                right        : 30px;
                width        : 25px;
                height       : 25px;
                border-radius: 50%;
                border       : 1px solid $brandColor2;
            }
        }
    }
}

.btnNext {
    padding-top: 20px;

    .btn {

        width          : 100%;
        margin         : 0 auto;
        height         : 50px;
        display        : flex;
        align-items    : center;
        justify-content: center;
        border         : none;
        color          : #fff;
        background     : $brandColor2;
        border-radius  : 25px;
        transition     : all 0.5s;

        &:hover {
            background: $brandColor1;
        }
    }
}