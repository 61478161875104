.contactPageWrap {}

.conatctWprap {
    padding-top: 203px;

    .contactRequest {
        margin-bottom: 95px;

        .contactRequestTop {
            max-width: 400px;
            width    : 100%;
            margin   : 0 auto 70px;

            .secTitle2 {}

            .contactPara {}
        }

        .contactRequestItem {
            @include boxs (0 0 6px 0 rgba(1, 1, 1, .3));
            @include br (20px);
            padding        : 20px;
            display        : flex;
            align-items    : center;
            justify-content: center;
            height         : 185px;
            @include ts (all 0.5s);
            margin-bottom: 30px;

            &:hover {
                @include boxs (0 5px 15px 0 rgba(1, 1, 1, .3));
            }

            .icon {
                font-size    : $f30;
                color        : $brandColor1;
                margin-bottom: 10px;

                i {}
            }

            .content {
                .conTitle {
                    font-size    : $f16;
                    font-family  : $pnb;
                    margin-bottom: 15px;
                }

                .conPara {
                    font-size: $f16;
                    color    : $grayColor;
                }
            }
        }
    }

    .contactFormWrap {
        margin-bottom: 70px;

        .contactForm {
            .form-group {
                margin-bottom: 30px;
                input[type="file"] {
                    display: none;
                }

                .fieldName {
                    margin-bottom: 5px;
                }

                .form-control {
                    @include boxs (none);

                    &.attachment {
                        border    : 1px dashed #bcbcbc;
                        background: #f2f2f2;
                        text-align: center;
                        cursor    : pointer;
                    }
                }

                textarea.form-control {
                    min-height: 115px;
                }
            }

            .btnGroup {
                padding-top: 45px;

                .btnSubmit {
                    border         : 1px solid $brandColor1;
                    background     : $brandColor1;
                    height         : 58px;
                    width          : 190px;
                    display        : flex;
                    align-items    : center;
                    justify-content: center;
                    @include br (29px);
                    font-size  : $f16;
                    font-family: $open;
                    font-weight: 600;
                    color      : $wColor;
                    @include ts (all 0.5s);

                    &:hover {
                        background: transparent;
                        color     : $brandColor1;
                    }
                }
            }
        }
    }
}

.ContactMap {
    height: 500px;

}

.ContactMap>div {
    position: relative !important;
}