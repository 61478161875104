// categaryWrap style


.categaryWrap {
    background: $wColor;
    padding   : 95px 0 65px;

    .categoryLeftContent {
        max-width: 500px;
        width: 100%;
        .secTopTitle {}

        .catTtitle {
            color      : $bColor;
            font-family: $pnb;
            font-size  : $f55;
            line-height: 64px;
        }

        .sepImg {
            margin-bottom: 28px;
        }

        .catPara {
            margin-bottom: 30px;

            span {
                margin-bottom: 5px;
                display      : inline-block;
            }
        }

        .joinBox {
            margin: 0;

            .joinForm {
                // @include boxs (0px 2px 10px 1px rgba(0, 0, 0, 0.05));

                .selectCountryCode {}

                .joinInputBox {
                    .joinInput {}

                    .joinBtn {}
                }
            }
        }
    }

    .catSingleItem {
        display        : flex;
        align-items    : center;
        justify-content: center;
        width          : 100%;
        background     : $brandColor1;
        @include br (25px);
        margin-bottom  : 30px;
        padding        : 35px 0 45px;
        text-decoration: none;
        @include ts (all 0.5s);

        &:hover {
            @include boxs (0px 0px 30px 0px rgba(23, 198, 122, 0.6));

            .catSingIcon {
                img {
                    @include tf (scale(1.1));
                }
            }
        }

        .itemInner {}

        .catSingIcon {
            max-width      : 100px;
            width          : 100%;
            height         : 100px;
            display        : flex;
            align-items    : center;
            justify-content: center;
            margin         : 0 auto 5px;

            img {
                max-width: 69px;
                width    : 100%;
                @include ts (all 0.5s);
            }
        }

        .catSingContent {
            h4 {
                font-size  : $f22;
                font-family: $open;
                line-height: 30px;
                color      : $wColor;
            }
        }

        &.digitalClassroom {

            .catSingIcon {
                img {}
            }
        }

        &.digitalContent {
            background: $digitalContent;

            &:hover {
                @include boxs (0px 0px 30px 0px rgba(41, 144, 238, 0.6));
            }

            .catSingIcon {
                img {}
            }
        }

        &.hajjContents {
            background: $hajjContents;

            &:hover {
                @include boxs (0px 0px 30px 0px rgba(13, 199, 199, 0.6));
            }

            .catSingIcon {
                img {}
            }
        }

        &.islamicLifestyle {
            background: $islamicLifestyle;

            &:hover {
                @include boxs (0px 0px 30px 0px rgba(102, 49, 210, 0.6));
            }

            .catSingIcon {
                img {}
            }
        }
    }
}

// categaryWrap style