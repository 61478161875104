.downlodAppWrap {
    background: url(../../../static/img/download_app/download_app_BG.jpg) no-repeat scroll top left / cover;
    padding   : 65px 0;

    .appLeftContent {
        .secTitle {
            color: $wColor;
            margin-bottom: 20px;
        }

        .appPara {
            color: $wColor;
            max-width: 370px;
            width: 100%;
            margin-bottom: 65px;
        }

        .appBtnGroup {
            display: flex;

            .appBtn {
                margin-right: 30px;
                @include ts (all 0.5s);

                &:last-child {
                    margin-right: 0;
                }

                svg {
                    fill     : $wColor;
                    max-width: 130px;
                    width    : 100%;
                }
                &:hover {
                    @include tf (scale(1.05));
                }
            }
        }
    }

    .appRightContent {
        position: relative;

        .appBgShape {
            position: relative;
            padding: 20px;

            &::before {
                position: absolute;
                content : "";
                top     : 50%;
                left    : 50%;
                width   : 0;
                height  : 0;
                @include ts (all 0.5s);
                @include tf (translate(-50%, -50%));
                background: $wColor;
                animation : wFull 2s linear infinite;
                @include br (50%);
            }

            img {}

        }

        .mobileShape {
            position: absolute;
            top     : 50%;
            left    : 50%;
            @include tf (translate(-50%, -50%));
            overflow: hidden;
            display : inline-block;

            .MobileShape {
                position: relative;
                z-index : 1;
            }


            .appSliderWrap {
                position: absolute;
                top     : 50%;
                left    : 50%;
                @include tf (translate(-50%, -50%));
                width   : 100%;
                height  : 100%;
                padding: 25px 15px;
                @include br (20px);


                .sliderContent {
                    height: 100%;

                    div {
                        height: 100%;
                    }

                    .appSlider {

                        .sliderItem {
                            outline: none;

                            img {
                                width  : 100%;
                                height : 100%;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes wFull {
    0% {
        opacity: 1;
        width  : 0;
        height : 0;
    }

    100% {
        opacity: 0;
        width  : 100%;
        height : 100%;
    }
}