@media (max-width: 1024px) {
    header.header .navbar .mainMenu .navbar-nav .nav-item .searchForm .searchInput {
        max-width: 150px;
    }

    header.header .navbar .mainMenu .navbar-nav .nav-item {
        margin-right: 15px;
    }

    .footerWrap .footerdownloadApp {
        margin-left: 0;
    }

    .downlodAppWrap .appRightContent .mobileShape .appSliderWrap {
        padding: 15px;
    }

    .pricingSingleItem {
        padding: 30px 15px 20px;
    }

    .pricingSingleItem .pricingInner .grtStartedBtn {
        padding-bottom: 30px;
    }

    .ourClientSayWrap .clientSingleItem .clientSayContent .clientSayInner .clientSayDetails {
        padding: 60px 30px 30px;
    }

    .ourClientSayWrap .clientSingleItem .clientSayContent .clientSayInner .clientSayDetails .comma {
        top: 60px;
    }

    .featuredSingleItem .featuredCourseFooter .courTimeList {
        padding: 0 20px;
    }

    .FeaturedInnerItem,
    .digitalQuarnItem {
        padding-bottom: 70px;
    }

    .courseDetailsSection .availableWeek .availableWeekList li {
        width       : 50px;
        height      : 50px;
        margin-right: 20px;
    }

    .feSingleItem {
        padding: 25px;
    }
}

// @media (max-width: 1024px)

@media (max-width: 991px) {


    header.header .navbar .mainMenu {
        padding-top: 30px;
    }

    header.header .navbar .mainMenu .navbar-nav {
        align-items: flex-start;
    }

    header.header .navbar .mainMenu .navbar-nav .nav-item {
        margin: 0 0 15px;
    }

    .bannerWrap .bannerInner .bannerInnerItem .bannerContent .bannerTitle {
        font-size  : 40px;
        line-height: 48px;
    }

    .categaryWrap .categoryLeftContent {
        margin-bottom: 80px;
        max-width    : 100%;
    }

    .OurCoursesWrap .courseFlowcartImg {
        margin-right: -200px;
    }

    .OurCoursesWrap .courseRight {
        padding-top: 0;
    }

    .OurCoursesWrap .courseSingleItem {
        margin-bottom: 100px;
    }

    .OurCoursesWrap .courseSingleItem .courseTitle {
        font-size    : 20px;
        margin-bottom: 10px;
    }

    .OurCoursesWrap {
        padding: 40px 0 0px;
    }

    .OurCoursesWrap .courseContent {
        margin-bottom: 45px;
    }

    .pricingSingleItem {
        margin-bottom: 50px;
    }

    .footerSingleItem {
        margin-bottom: 60px;
    }
}

//  @media (max-width: 991px)

@media (max-width: 767px) {

    header.header .navbar .mainMenu {
        max-height: 315px;
        overflow-x: hidden;
    }

    header.header .afterLogin .dropdown-menu {
        margin-top      : 20px;
        // display: block !important;
        max-height      : 0;
        transition      : max-height 1s ease-out !important;
        transition-delay: 0s !important;
    }

    header.header .afterLogin .dropdown-menu.show {
        max-height: 500px;
        transition: max-height 1s ease-in !important;
    }

    .bannerWrap .bannerInner .bannerInnerItem .bannerContent {
        padding: 80px 0 40px;
    }

    .bannerWrap .bannerInner .bannerInnerItem .bannerContent .bannerTitle {
        font-size         : 40px;
        line-height       : 50px;
        overflow          : hidden;
        text-overflow     : ellipsis;
        display           : -webkit-box;
        max-height        : 100px;
        min-height        : 100px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .categaryWrap .categoryLeftContent .catTtitle {
        font-size  : 30px;
        line-height: 36px;
    }

    .categaryWrap .categoryLeftContent .catTtitle br {
        display: none;
    }

    .courseLeft {
        text-align: left !important;
    }

    .OurCoursesWrap .courseSingleItem {
        margin-bottom: 50px;
    }

    .OurCoursesWrap .courseSingleItem .courseTitle br {
        display: none;
    }

    .footerWrap .footerTop {
        padding-bottom: 20px;
    }

    .featuredCourseWrap {
        padding: 70px 0 20px;
    }

    .secTitle {
        font-size  : 30px;
        line-height: 40px;
    }

    .downlodAppWrap {
        padding: 50px 0;
    }

    .downlodAppWrap .appLeftContent .appPara {
        margin-bottom: 35px;
    }

    .secTitle2 {
        font-size    : 30px;
        line-height  : 36px;
        margin-bottom: 20px;
    }

    .featuredTop {
        .secTitle2 {
            margin-bottom: 20px;
        }
    }

    .OurCoursesWrap .courseSingleItem .coutsePara br {
        display: none;
    }

    .pricingSingleItem {
        min-height: 400px;
    }

    .pricingSingleItem .pricingInner .priceForList {
        margin-bottom: 100px;
    }

    .footerWrap .footerSingleItem .footerLogoWrap {
        max-width: 100%;
    }

    .footerBottom .row {
        flex-direction: column-reverse;
    }

    .footerWrap .footerBottom .footerSocial .solialLink {
        justify-content: center;
        margin-bottom  : 20px;
    }

    .footerWrap .footerBottom .footerCopyRight .copy {
        text-align: center;
    }

    .categaryWrap {
        padding: 60px 0 50px;
    }

    .categaryWrap .categoryLeftContent {
        margin-bottom: 50px;
    }

    .pricePageWrap .priceTableWrap {
        padding-bottom: 30px;
    }

    .conatctWprap .contactRequest {
        margin-bottom: 30px;
    }

    .quizSingleBody {
        padding: 90px 0;
    }

    .CourseDetailsWrap .courseDetailsLeft {
        padding-right: 15px;
        margin-bottom: 30px;
    }

    .column-reverse {
        flex-direction: column-reverse;
    }

    .hadithDetailsWrap .CourseDetailsWrap .column-reverse .courseDetailsLeft .courseImgSliderContent {
        margin-bottom: 0;
    }

    .coursePriceRightContent .coursePriceContent {
        margin-bottom: 50px;
    }

    .courseFaqWrap .courseFaq .accordion .card-header {
        padding-left : 20px;
        padding-right: 20px;
    }

    .courseFaqWrap .courseFaq .accordion .card-header .faqQuestion {
        position: relative;
    }

    .courseFaqWrap .courseFaq .accordion .card-header .faqQuestion .rightIcon {
        position: absolute;
        top     : 0;
        right   : 0;
    }

    .courseFaqWrap {
        margin-bottom: 60px;
    }

    .courseDteailsBannerContent .whyChoseCourse {
        padding: 40px 30px 0px;
    }

    .footerSingleItem {
        text-align: center;
    }

    .notefySingleItem .tableInfo .myCourseLogo {
        padding  : 0 20px;
        min-width: 140px;
    }

    .notefySingleItem .tableDropDown .dots .dropdown .dropdown-menu {
        transform: translate3d(0px, 36px, 0px) !important;
    }

    .notefySingleItem {
        padding: 10px 0;
    }

    .FeaturedInnerItem,
    .digitalQuarnItem {
        padding: 120px 0 50px;
    }

    .bannerInnerWrap .innerBanner {
        min-height: 100px;
        padding   : 20px 30px 0;
        margin-top: -50px;
    }

    .largestCoursesWrap {
        padding: 90px 0 25px;
    }

    .LiveInstructorList .FeaturedInnerItem {
        padding-bottom: 30px;
    }

    .bannerInnerWrap .innerBanner .innerBannerTop {
        margin-bottom: 0;
    }

    .bannerInnerWrap .innerBanner .innerBannerTop .secTitle2 {
        margin-bottom: 0;
    }

    .myCourseTableWrap .table-responsive .table tr td {
        display: block;
        padding: 30px 20px 30px;
    }

    .myCourseTableWrap .table-responsive .table tr td:nth-child(2) {
        padding-right: 0;
    }

    .myCourseTableWrap .table-responsive .table tr td:nth-child(1) {
        padding-bottom: 0;
    }

    .myCourseLogo img {
        max-width    : 100px;
        height       : 100px;
        margin-bottom: 0;
    }

    .myCourseTableWrap {
        padding: 0;
    }

    .offeredLogo {
        margin-top: 20px;
    }

    table.ramadanCalendarTable tr th,
    table.ramadanCalendarTable tr td {
        vertical-align: top;
        padding       : 5px;
        font-size     : 12px;
    }

    .CourseDetailsWrap .courseDetailsLeft.hadithForMobile {
        position: absolute;
        top     : 0;
        left    : -100%;
        padding : 0;
        z-index : 1;
        @include ts (all 0.5s);
        opacity   : 0;
        visibility: hidden;

        &.active {
            opacity   : 1;
            visibility: visible;
            left      : 0;
        }
    }

    .hadithForMobile .goBack {
        width          : 40px;
        height         : 40px;
        display        : flex;
        align-items    : center;
        justify-content: center;
        background     : #17c67a;
        border-radius  : 50%;
        color          : #fff;
        margin-right   : 10px;
    }

    .hadithDetailsWrap .CourseDetailsWrap .courseDetailsLeft.hadithForMobile .courseImgSliderContent {
        @include boxs (-0.07px -1.999px 24px 0px rgba(118, 118, 118, 0.43));
        padding: 20px 10px;
    }

    .hadithDetailsWrap .CourseDetailsWrap .courseDetailsLeft.hadithForMobile .courseImgSliderContent .hadithBody {
        padding   : 0 10px;
        height    : max-content;
        max-height: 520px;
    }

    .hadithForMobile .hadithTop p {
        font-size: 16px;
        display  : flex;

        b {
            overflow          : hidden;
            text-overflow     : ellipsis;
            display           : -webkit-box;
            max-height        : 22px;
            min-height        : 22px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            max-width         : 140px;
        }
    }

    .hadithForMobile .hadithDetailsWrap .CourseDetailsWrap .courseDetailsRight .playListWrap {
        max-height: 300px;
    }
    .hadithDetailsWrap .CourseDetailsWrap .courseDetailsRight.hadithForMobile .playListWrap {
        height: max-content;
        min-height: 250px;
        max-height: 300px;
    }

    .hadithBottm {
        padding-top: 20px;
    }
}

//  @media (max-width: 767px)


@media (max-height: 420px) {
    header.header .navbar .mainMenu {
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 220px;
    }
}

//  @media (max-height: 420px) 