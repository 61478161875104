@mixin tf($property) {
    -webkit-transform: $property;
    -ms-transform    : $property;
    transform        : $property;
}

@mixin ts($property) {
    -webkit-transition: $property;
    -ms-transition    : $property;
    transition        : $property;
}

@mixin op($property) {
    -webkit-opacity: $property;
    -ms-opacity    : $property;
    opacity        : $property;
}

@mixin boxs($property) {
    -webkit-box-shadow: $property;
    -ms-box-shadow    : $property;
    box-shadow        : $property;
}

@mixin br($property) {
    -webkit-border-radius: $property;
    -moz-border-radius   : $property;
    border-radius        : $property;
}

@mixin plc ($property) {
    &::placeholder {
        color: $property;
    }

    &:-ms-input-placeholder {
        color: $property;
    }

    &::-ms-input-placeholder {
        color: $property;
    }
}

@mixin bgGradient($value1, $value2) {
    background: -webkit-linear-gradient(to right, $value1, $value2);
    background: -moz-linear-gradient(to right, $value1, $value2);
    background: -ms-linear-gradient(to right, $value1, $value2);
    background: -o-linear-gradient(to right, $value1, $value2);
    background: linear-gradient(to right, $value1, $value2);
}

@mixin an($value1) {
    -webkit-animation: $value1;
    -moz-animation   : $value1;
    -o-animation     : $value1;
    animation        : $value1;
}


@mixin ap($value1) {
    -moz-appearance   : $value1;
    -webkit-appearance: $value1;
}