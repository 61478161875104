.ourClientSayWrap {
    padding: 75px 0 95px;
    .ourClientTopContent {
        .secTitle2 {}
    }

    .clientSingleItem {
        .clientSayContent {
            .clientSayInner {
                .clientSayDetails {
                    position: relative;
                    @include boxs (0px 10px 20px 0px rgba(118, 118, 118, 0.4));
                    background: $wColor;
                    @include br (25px);
                    padding      : 90px 40px 65px;
                    margin-bottom: 60px;

                    &::before {
                        position    : absolute;
                        content     : "";
                        bottom      : -20px;
                        left        : 55px;
                        border-top  : 35px solid $wColor;
                        border-right: 35px solid transparent;
                    }

                    .comma {
                        color      : $commaColor;
                        font-family: $pnr;
                        font-size  : $f166;
                        position   : absolute;
                        top        : 85px;
                        left       : 40px;
                    }

                    .clientSay {
                        color      : $darkLight;
                        font-family: $open;
                        font-size  : $f14;
                        line-height: 25px;
                    }
                }
            }
        }

        .clientDetailWrap {
            .clientDetailsInner {
                display    : flex;
                align-items: center;

                .clientImg {
                    margin-right: 25px;

                    img {
                        width        : 112px;
                        height       : 112px;
                        border-radius: 50%;
                        background   : $borderColor;
                        padding      : 1px;
                    }
                }

                .clientDetails {
                    .clientName {
                        color      : $clientName;
                        font-size  : $f16;
                        line-height: 25px;
                        font-family: $pnr;
                    }

                    .cleintFrom {
                        color      : $fetColor;
                        font-family: $open;
                        font-size  : $f14;
                        line-height: 25px;
                    }
                }
            }
        }
    }

    .ourClientSlider {
        .ourClientSliderItem {
            padding-top: 40px;
            outline    : none;
        }
    }
}