.modal-open {
    padding-right: 0 !important;
}

.loginModal {
    .modal-dialog {
        max-width: 600px;

        .modal-content {
            @include br (40px);

            .modal-header {
                .modal-title {
                    width: 100%;

                    .modalTitle {
                        font-family: $pnr;
                        color      : $brandColor2;
                    }
                }

                .close {
                    color      : #a7c8e3;
                    padding    : 0;
                    opacity    : 1;
                    right      : 30px;
                    position   : absolute;
                    top        : 15px;
                    margin     : 0;
                    font-weight: 100;
                    font-size  : 30px;

                }
            }

            .modal-body {
                padding: 50px;

                .loginModalBody {
                    .loginForm {
                        .get-opt-form {
                            .log-title {
                                color        : #8894a6;
                                font-weight  : 400;
                                padding-left : 30px;
                                margin-bottom: 10px;
                            }

                            .otp-content {
                                position: relative;

                                .text-danger {
                                    position : absolute;
                                    top      : 47px;
                                    left     : 0;
                                    font-size: 12px;
                                    padding-left: 30px;
                                }

                                .input-number {
                                    position     : relative;
                                    margin-bottom: 30px;

                                    .form-control {
                                        height: 50px;
                                        border: 2px solid #a7c8e3;
                                        @include br (20px);
                                        @include boxs (none);
                                        @include plc (#c0cfe4);
                                        padding: 0 30px;
                                    }

                                    .icon {
                                        position: absolute;
                                        right   : 20px;
                                        top     : 50%;
                                        @include tf (translateY(-50%));

                                        i {
                                            color    : #e0e6f1;
                                            color    : #c0cfe4;
                                            font-size: $f30;
                                        }
                                    }
                                }
                            }

                            .btnGroup {
                                button {
                                    font-weight: 500;
                                    font-size  : $f18;
                                    background : $brandColor2;
                                    border     : none;
                                    @include br (25px);
                                    height: 50px;
                                    @include ts (all 0.5s);
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: $wColor;

                                    &:hover {
                                        background: $brandColor1;
                                    }
                                    i {
                                        margin-left: 20px;
                                    }
                                }
                            }

                            .opt-input-group {
                                display        : flex;
                                justify-content: center;
                                margin-bottom  : 20px;

                                .form-control {
                                    margin    : 0 10px 10px;
                                    height    : 60px;
                                    width     : 60px;
                                    background: #eee;
                                    border    : 2px solid #a7c8e3;
                                    @include br (20px);
                                    @include boxs (none);
                                    pointer-events: none;

                                    &:focus {
                                        background    : #fff;
                                        pointer-events: visible;
                                        @include boxs (0 0 10px 1px rgba(3, 148, 215, 0.5));
                                    }
                                }
                            }
                        }

                        .newToIqra {
                            padding-top  : 10px;
                            margin-bottom: 30px;

                            p {
                                color      : #8894a6;
                                font-size  : $f14;
                                font-family: $pnr;

                                a {
                                    color         : $brandColor2;
                                    font-weight   : 700;
                                    font-size     : $f18;
                                    font-family   : $pnr;
                                    text-transform: uppercase;
                                    @include ts (all 0.5s);

                                    &:hover {
                                        color: $brandColor1;
                                    }
                                }
                            }
                        }

                        .accecptCondition {
                            display: flex;

                            .customCheckbok {
                                input[type="checkbox"] {
                                    display: none;

                                    &:checked {
                                        &+label::before {
                                            content: "✔";
                                        }
                                    }
                                }

                                label {
                                    margin      : 0;
                                    position    : relative;
                                    cursor      : pointer;
                                    padding-left: 40px;
                                    color       : #8894a6;

                                    a {
                                        color: $brandColor2;
                                    }

                                    &::before {
                                        position   : absolute;
                                        content    : "";
                                        font-family: 'FontAwesome';
                                        left       : 0;
                                        width      : 20px;
                                        height     : 20px;
                                        border     : 1px solid #8894a6;
                                        color      : #000;
                                        top        : 50%;
                                        @include tf (translateY(-50%));
                                        @include br (5px);
                                        display        : flex;
                                        align-items    : center;
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}