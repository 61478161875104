.privacyPolicy {
    padding: 70px 0;

    .secTitle {
        margin-bottom: 30px;
    }

    p {
        margin-bottom: 20px;
    }

    ul {
        margin-bottom: 30px;

        li {
            margin-bottom: 10px;
            a {
                color: $brandColor1;
            }
        }
    }

    .courseFaq {
        margin-bottom: 70px;

        .faqSungleItem {
            .faqQuestion {
                cursor: pointer;
            }

            .card-body {
                border    : 1px solid #ddd;
                border-top: none;
            }
        }
    }

}