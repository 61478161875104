.videoChat {
    position: relative;

    .chatImg {
        img {
            width: 100%;
        }
    }

    .callBrnGroup {
        position: absolute;
        left    : 0;
        bottom  : 70px;
        z-index : 1;
        width   : 100%;

        .callBtn {
            padding        : 0;
            margin         : 0;
            list-style     : none;
            display        : flex;
            align-items    : center;
            justify-content: center;

            li {
                display        : flex;
                align-items    : center;
                justify-content: center;
                @include br (50%);
                background-color: $wColor;
                @include boxs (0px 2px 35px 0px rgba(118, 118, 118, 0.65));
                width    : 40px;
                height   : 40px;
                margin   : 0 15px;
                font-size: 20px;
                position : relative;
                cursor   : pointer;

                &:before,
                &:after {
                    position: absolute;
                    content : "";
                    width   : 30px;
                    height  : 2px;
                    display : none;
                }

                &:before {
                    background: white;
                    top       : calc(50% - 1px);
                    left      : calc(50% - -1px);
                    transform : translate(-50%, -50%) rotate(45deg);
                }

                &:after {
                    background: #17c67a;
                    transform : rotate(45deg);
                }

                &:nth-child(1) {
                    color: $brandColor1;
                }

                &:nth-child(2) {
                    color : $brandColor2;
                    width : 50px;
                    height: 50px;
                    @include tf (scale(1.0) rotate(135deg));
                    @include boxs (0px 0px 0px 10px rgba(255, 255, 255, 0.5));
                    font-size: 30px;
                    z-index  : 1;

                    &:before {
                        position     : absolute;
                        content      : "";
                        top          : 0;
                        left         : 0;
                        width        : 100%;
                        height       : 100%;
                        transition   : all 0.5s;
                        transform    : translate(-50%, -50%);
                        background   : #fff;
                        animation    : live 3s linear infinite;
                        border-radius: 50%;
                        z-index      : -1;
                        display      : block !important;
                    }

                    &:after {
                        display: none !important;
                    }
                }

                &:nth-child(3) {
                    color    : #fcb01d;
                    font-size: 25px;

                    &:after {
                        background: #fcb01d;
                    }
                }

                i {}

                &.inactive {

                    &:before,
                    &:after {
                        display: block;
                    }
                }
            }
        }
    }

    .callerImg {
        position: absolute;
        right   : 50px;
        bottom  : 50px;
        width   : 70px;
        height  : 70px;
        @include br (50%);
        overflow: hidden;
        @include boxs (0px 0px 15px 0px rgba(0, 0, 0, 0.5));
        border: 3px solid $wColor;

        img {}
    }
}


.videoChat {
    width: 100%;
    border: none;
    min-height: 500px;
    overflow: hidden;
}