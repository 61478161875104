
//  custom fonts
@import "./fonts/proxima_nova/stylesheet.css";
@import "./fonts/flat-icon/flaticon.css";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css');
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');


//  animation 
@import "./animation/animate.css";



//  themes custom style
@import "./themes/variable";
@import "./themes/mixin";
@import "./themes/globalStyle";
@import "./themes/header/header";
@import "./themes/banner/banner";
@import "./themes/categary/categary";
@import "./themes/featuredCourse/featuredCourse";
@import "./themes/downloadApp/downloadApp";
@import "./themes/ourCourse/ourCourses";
@import "./themes/priceTable/priceTable";
@import "./themes/ourClientSay/ourClientSay";
@import "./themes/contact/contact";
@import "./themes/CourseDetails/CourseDetails";
@import "./themes/namazLearning/namazLearning";
@import "./themes/myCourses/myCourses";
@import "./themes/modal/loginModal";
@import "./themes/groupLearning/groupLearning";
@import "./themes/userProfile/userProfile";
@import "./themes/modal/enrollConfirm";
@import "./themes/quiz/quiz";
@import "./themes/videoChat/videoChat";
@import "./themes/notification/notofication";
@import "./themes/privacyPolicy/privacyPolicy";
@import "./themes/tasbih/tasbih";


@import "./themes/footer/footer";
@import "./themes/responsive";