.modal-open {
    height: 100vh;
}

.modal-backdrop {
    background-color: $wColor;

    &.show {
        opacity: 0.8;
    }
}

.modal-content {
    border-radius: 30px;
    box-shadow   : 0px 0px 20px 0px rgba(4, 199, 166, 0.5);
    border       : none;
}

.enrollConfirm {

    .modal-dialog {
        max-width: 700px;

        .modal-content {


            &:before {
                position       : absolute;
                content        : "\f00c";
                font-family    : "fontawesome";
                font-weight    : 700;
                font-size      : 25px;
                width          : 50px;
                height         : 50px;
                display        : flex;
                align-items    : center;
                justify-content: center;
                margin         : 0 auto;
                left           : 0;
                right          : 0;
                top            : 0;
                color          : #fff;
                background     : #04c7a6;
                border-radius  : 50%;
                transform      : translateY(-50%);
                box-shadow     : 0px 0px 20px 0px rgba(4, 199, 166, 0.8);
            }

            .modal-header {
                text-align: center;

                .modal-title {
                    padding-top   : 30px;
                    width         : 100%;
                    padding-bottom: 10px;
                    color         : #04c7a6;
                }
            }

            .modal-body {

                h3 {
                    font-size    : 30px;
                    text-align   : center;
                    margin-bottom: 30px;

                    b {
                        color: #04c7a6;
                    }
                }

                .table-responsive {}

                .table {
                    margin-bottom: 0;

                    tr {

                        th,
                        td {
                            padding  : 5px 15px;
                            font-size: 16px;
                            border   : none;

                            .availableWeekList {
                                padding   : 0;
                                margin    : 0;
                                list-style: none;

                                li {
                                    display     : inline-block;
                                    padding-left: 5px;

                                    &:first-child {
                                        padding-left: 0;
                                    }
                                }
                            }
                        }
                    }
                }

            }

            .modal-footer {
                padding        : 20px;
                justify-content: center;

                .btn-secondary {
                    width        : 100px;
                    height       : 40px;
                    border-radius: 10px;
                    background   : #04c7a6 !important;
                    border       : none;
                    box-shadow   : none !important;

                    &:hover {
                        background: #0d9982 !important;
                    }
                }
            }
        }
    }
}