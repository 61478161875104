.priceTableWrap {
    background: url(../../../static/img/price/price_bg.jpg);
    padding   : 80px 0;

    .priceTableTop {
        margin-bottom: 40px;

        .secTitle2 {
            color: $wColor;
        }
    }
}

.pricingSingleItem {
    background: $wColor;
    @include boxs (0px 2px 35px 0px rgba(118, 118, 118, 0.58));
    @include br (35px);
    padding        : 50px 40px 80px;
    position       : relative;
    display        : flex;
    justify-content: center;
    margin-bottom  : 20px;
    min-height     : 555px;
    @include ts (all 0.5s);

    &.pricingSingleItemSub {
        background: $brandColor2;

        .pricingInner {
            .priceCategary {
                color: $wColor;
            }

            .secTitle {
                padding-top: 10px;
                color      : $wColor;
                text-transform: capitalize;
            }
            .price {
                .priceInner {
                    .priceCount {
                        color: $wColor;
                    }
                    .bdtC svg {
                        fill: $wColor;
                    }
                }
            }
            .priceForList li {
                color: $wColor;
            }
            .grtStartedBtn .btnStart {
                background: $brandColor1;
                &:hover {
                    @include boxs (0px 10px 30px 0px rgba(255, 255, 255, 0.5));
                    @include boxs (0px 10px 30px 0px rgba(0, 0, 0, 0.3));
                }
            }
        }
    }

    &:hover {
        @include tf (translateY(-8px));
    }

    .pricingInner {
        width: 100%;

        .priceCategary {
            font-size     : $f20;
            font-family   : $pnr;
            line-height   : 28px;
            text-transform: uppercase;
            color         : $brandColor2;
        }

        .price {
            padding: 40px 0 30px;

            .priceInner {
                display        : flex;
                align-items    : flex-end;
                justify-content: center;

                .priceCount {
                    font-size  : $f95;
                    font-family: $pnb;
                    line-height: 70px;
                    color      : $priceColor;
                }

                .bdtC {
                    margin-left: 5px;

                    svg {
                        fill: $priceColor;
                    }
                }
            }
        }

        .priceForList {
            margin    : 0 0 35px;
            padding   : 0;
            list-style: none;

            li {
                font-family: $open;
                font-size  : $f14;
                line-height: 28px;
                color      : $dark;
            }
        }

        .grtStartedBtn {
            position: absolute;
            left    : 0;
            bottom  : 0;
            padding : 0px 40px 40px;
            width   : 100%;

            .btnStart {
                display        : flex;
                font-weight    : 700;
                font-size      : $f12;
                line-height    : 18px;
                max-width      : 255px;
                margin         : 0 auto;
                width          : 100%;
                height         : 40px;
                align-items    : center;
                justify-content: center;
                background     : $brandColor2;
                color          : $wColor;
                @include br (20px);
                @include ts (all 0.5s);

                &:hover {
                    @include boxs (0px 10px 30px 0px rgba(41, 144, 238, 0.6));
                }
            }
        }

        .pricingTabContent {
            padding: 22px 0;

            .pricTAb {
                margin         : 0;
                padding        : 0;
                list-style     : none;
                display        : flex;
                align-items    : center;
                justify-content: center;
                background     : $tabBgColor;
                max-width      : 255px;
                width          : 100%;
                margin         : 0 auto;
                flex-wrap      : initial;
                @include br (18px);
                position: relative;
                border  : none;

                .overlayBg {
                    @include br (18px);
                    background: $wColor;
                    height    : 100%;
                    width     : 85px;
                    position  : absolute;
                    left      : 0;
                    top       : 50%;
                    @include tf (translateY(-50%));
                    @include ts (all 0.5s);
                }

                .nav-item {
                    color          : $wColor;
                    font-size      : $f12;
                    font-family    : $open;
                    text-transform: capitalize;
                    line-height    : 20px;
                    font-weight    : 600;
                    display        : flex;
                    align-items    : center;
                    justify-content: center;
                    width          : 100%;
                    height         : 38px;
                    z-index        : 1;
                    @include br (18px);
                    @include ts (all 0.5s);
                    cursor: pointer;
                    border: none;
                    margin: 0;

                    &.active {
                        color: $dark;
                        @include boxs (0px 0px 50px 0px rgba(155, 155, 155, 0.4));
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

.pricePageWrap {
    .priceTableWrap {
        background : $wColor;
        padding-top: 150px;

        .priceTableTop {
            .secTitle2 {
                color: $brandColor2;
            }
        }

        .pricingSingleItem {
            background: $brandColor1;

            .pricingInner {
                .priceCategary {
                    color: $wColor;
                }

                .price {
                    .priceInner {
                        .priceCount {
                            color: $wColor;
                        }

                        .bdtC svg {
                            fill: $wColor;
                        }
                    }
                }

                .priceForList {
                    li {
                        color: $wColor;
                    }
                }

                .grtStartedBtn {
                    .btnStart {
                        background: $wColor;
                        color     : $brandColor1;

                        &:hover {
                            @include boxs (0px 10px 30px 0px rgba(0, 0, 0, 0.3));
                        }
                    }
                }

                .pricingTabContent {
                    .pricTAb {
                        background: $brandColor1;

                        .nav-item {
                            &.active {
                                @include boxs (0 0 10px 0 rgba(1, 1, 1, .3));
                            }
                        }
                    }
                }
            }

            &.active {
                background: $brandColor2;

                .pricingInner {
                    .grtStartedBtn {
                        .btnStart {
                            color: $brandColor2;
                        }
                    }
                }
            }
        }
    }
}