.userProfileWrap {}

.userInfoWrap {
    padding: 230px 0 100px;

    .userProfile {
        .userProfilePic {
            @include boxs (0px 9px 27px 0px rgba(56, 60, 59, 0.15));
            width   : 185px;
            height  : 185px;
            margin  : 0 auto 30px;
            position: relative;
            @include br (50%);

            &+.saveBtn {
                justify-content: center;
            }

            img {
                border: 1px solid $borderColor;
                width : 100%;
                height: 100%;
            }

            .upProfileImg {
                position: absolute;
                top     : 0;
                left    : 0;
                height  : 100%;
                width   : 100%;
                @include br (50%);
                display        : flex;
                align-items    : flex-end;
                justify-content: center;
                overflow       : hidden;

                input[type="file"] {
                    display: none;
                }

                label {
                    width     : 100%;
                    padding   : 15px;
                    color     : $wColor;
                    cursor    : pointer;
                    background: rgba(3, 148, 215, 0.6);
                    margin    : 0;

                    i {
                        font-size: 20px;
                        @include ts (all 0.5s);
                    }

                    &:hover {
                        i {
                            @include tf (scale(1.3));
                        }
                    }
                }

                .errorNote {
                    color       : rgb(255, 0, 83);
                    padding-left: 10px;
                    position    : absolute;
                    top         : 50px;
                    font-size   : 14px;
                    line-height : 20px;
                    padding     : 0 20px;
                }
            }
        }

        .userInfoText {
            .userName {
                font-family  : $pnb;
                font-size    : $f30;
                line-height  : 36px;
                color        : $brandColor2;
                margin-bottom: 5px;

            }

            .userType {
                font-family: $pnr;
                font-size  : $f24;
                line-height: 30px;
                color      : $brandColor1;
                margin-bottom: 30px;
            }
        }
    }

    .userDetailsWrap {
        .userenrCoursess {
            background: $grayLightBg;
            @include br (30px);
            margin-bottom: 100px;
            padding      : 40px 35px 0;

            .row {
                @media (max-width: 767px) {
                    display: block;
                    padding-bottom: 20px;
                }
            }

            .courseItem {
                margin-bottom: 35px;

                h2 {
                    font-family  : $pnb;
                    font-size    : $f50;
                    line-height  : 66px;
                    color        : $brandColor1;
                    margin-bottom: 5px;
                }

                p {
                    font-family: $pnr;
                    font-size  : $f24;
                    line-height: 30px;
                    color      : $brandColor2;
                }
            }
        }
    }

    .userUpdateFormWrap {
        .userUpdateForm {
            .basicInfo {
                background: $grayLightBg;
                @include br (30px);
                padding      : 30px 55px 10px;
                margin-bottom: 40px;

                .basicInfoTitle {
                    font-family  : $pnr;
                    font-size    : $f30;
                    line-height  : 36px;
                    color        : $brandColor2;
                    margin-bottom: 15px;
                }

                .form-group {
                    margin-bottom: 25px;

                    .inputTitle {
                        font-family: $pnr;
                        font-size  : $f18;
                        line-height: 30px;
                        color      : $dark;
                    }

                    .form-control {
                        border    : none;
                        background: $wColor;
                        @include boxs (none);
                        font-family: $pnr;
                        font-size  : $f16;
                        line-height: 30px;
                        color      : $dark;
                    }

                    .genderSelect {
                        .selectGender {
                            .genInput {
                                input[type="radio"] {
                                    display: none;

                                    &:checked {
                                        &+label {
                                            &::before {
                                                border: 5px solid $brandColor2;
                                            }
                                        }
                                    }
                                }

                                label {
                                    padding-left : 25px;
                                    position     : relative;
                                    margin-bottom: 10px;
                                    cursor       : pointer;

                                    &::before {
                                        position     : absolute;
                                        content      : "";
                                        top          : 4px;
                                        left         : 0;
                                        width        : 16px;
                                        height       : 16px;
                                        border-radius: 50%;
                                        border       : 1px solid $brandColor2;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    .saveBtn {
        display        : flex;
        justify-content: flex-end;
        margin-bottom  : 50px;

        .btnSave {
            font-family    : $pnr;
            font-size      : $f20;
            line-height    : 30px;
            color          : $wColor;
            background     : $brandColor1;
            width          : 150px;
            height         : 50px;
            display        : flex;
            justify-content: center;
            align-items    : center;
            @include br (25px);
            @include ts (all 0.5s);

            &:hover {
                background: $brandColor2;
            }
        }
    }
}

.enrolledCourse {
    .enrolledCourseTitle {
        font-family  : $pnb;
        font-size    : $f30;
        line-height  : 36px;
        color        : $dark;
        padding      : 0 18px;
        margin-bottom: 20px;
    }

    .myCourseTableWrap {
        padding: 0;
    }
}