.OurCoursesWrap {
    padding: 78px 0 90px;

    .courseContent {
        margin-bottom: 85px;

        .secTitle2 {}
    }

    .courseSingleItem {
        margin-bottom: 200px;

        .courseTitle {
            font-family: $pnb;
            font-size  : $f23;
        }

        .coutsePara {
            color    : $courseColor;
            font-size: $f14;
        }
    }

    .courseLeft {}

    .courseRight {
        padding-top: 155px;
    }

    .courseFlowcartImg {
        margin-right: -300px;

        img {}
    }
}

.courseImgSliderWrap {
    margin-bottom: -24px;
    .courseImgSlider {
        .courseImgSliderItem {
            .courseImgSliderItemInner {
                position: relative;
                padding-bottom: 24px;

                .overlaImg {
                    position: absolute;
                    bottom  : 0;
                    left    : 0;
                }

                outline: none;

                img {
                    width: 100%;
                }
            }
        }
        .slick-dots {
            bottom: 50px;
            right: 0;
            text-align: right;
            padding-right: 50px;
            li {
                width: 28px;
                height: 10px;
                @include br (5px);
                background: $wColor;
                &.slick-active {
                    background: $brandColor2;
                }
                button {
                    display: none;
                }
            }
        }
    }
}