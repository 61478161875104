.NamazLearningPageWrap {
    .digitalQuarnItem {
        padding-bottom: 40px;
    }
}

.digitalQuarnItem {}

.singleItem {
    margin-bottom: 30px;
}

.namazLearningSingleItem {
    background: $wColor;
    @include boxs (0px 0px 20px 0px rgba(0, 0, 0, 0.2));
    @include br (20px);
    padding: 25px 15px 25px 25px;
    @include ts (all 0.5s);
    display        : block;
    text-decoration: none;
    position       : relative;
    width          : 100%;
    height         : 100%;

    &::before {
        position  : absolute;
        content   : "";
        top       : 65px;
        right     : 0;
        width     : 123px;
        height    : 2px;
        background: $brandColor1;
        @include op (0.4);
        @include ts (all 0.5s);
    }

    .namazItemTop {
        display        : flex;
        justify-content: space-between;
        margin-bottom  : 20px;

        .inamazTopIcon {
            svg {
                max-width: 55px;
                width    : 100%;
                fill     : $brandColor1;
                @include ts (all 0.5s);
            }
        }

        .videoCount {
            margin-top: -10px;
            text-align: right;

            p {
                font-size  : $f13;
                line-height: 20px;
                margin     : 0;
                font-weight: 400;
                color      : $grayColor;
                @include ts (all 0.5s);
            }
        }

        .hadithChapter {
            margin-top: -10px;
            text-align: right;

            p {
                font-size  : $f13;
                line-height: 20px;
                margin     : 0;
                font-weight: 400;
                color      : $grayColor;
                @include ts (all 0.5s);
            }
        }
    }

    .namazItemFooter {
        .namazFooterTitle {
            font-size  : $f20;
            font-weight: 600;
            color      : $dark;
            @include ts (all 0.5s);
        }
    }

    &:hover {
        @include tf (translateY(-4px));
        background: $brandColor1;

        &:before {
            background: $wColor;
        }

        .namazItemTop {

            .inamazTopIcon {
                svg {
                    fill: $wColor;
                }
            }

            .videoCount {
                p {
                    color: $wColor;
                }
            }
        }

        .namazItemFooter {
            .namazFooterTitle {
                color: $wColor;
            }
        }
    }
}