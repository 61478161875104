.notificationWrap {
    .digitalQuarnItem {}
}

.notifyTable {
    .notifyCount {
        margin-bottom: 20px;
    }
    .tableContent {}
}

.notefySingleItem {
    border-bottom: 1px solid $borderColor;
    padding: 10px 0 20px;
    @include ts (all 0.5s);
    display: block;
    &:hover {
        background: $borderColor;
    }

    &:last-child {
        border: none;
    }

    .tableDropDown {
        display        : flex;
        align-items    : center;
        justify-content: flex-end;

        .ago {
            margin-right: 10px;
            color       : $grayColor;
            font-size: $f12;
        }

        .dots {
            .dropdown {
                .dropdown-toggle {
                    background: transparent;
                    border    : none;

                    &:after {
                        display: none;
                        margin-left: .255em;
                        vertical-align: .255em;
                        content: "";
                        border-top: 20px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 20px solid #ddd;
                        border-left: 10px solid transparent;
                        position: absolute;
                        top: 5px;
                        right: 5px;
                    }
                    &[aria-expanded="true"] {
                        &:after {
                            display: block;
                        }
                    }

                    i {
                        color       : $grayColor;
                        font-size: $f20;
                    }
                }

                .dropdown-menu {
                    padding: 0 0px;
                    left   : auto !important;
                    right  : 0;

                    .dropdown-item {
                        border-bottom: 1px solid $borderColor;
                        padding      : 5px 20px;
                        font-size    : 14px;

                        &:last-child {
                            border: none;
                        }

                        i {
                            margin-right: 15px;
                        }
                    }
                }
            }
        }
    }

    .tableInfo {
        display: flex;

        .myCourseLogo {
            padding  : 0 30px;
            min-width: 160px;

            img {
                max-width: 100px;
            }
        }

        .notifyText {

            padding-top: 10px;
            padding-right: 30px;

            p {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                max-height: 75px;
                min-height: 75px;
                -webkit-line-clamp: 3;
            }
        }
    }
}