// header style

header.header {
    @include bgGradient ($brandColor1, $brandColor2);
    padding: 20px 0;
    @include ts (top 0.3s ease 0s);
    position: sticky;
    z-index : 10;
    top     : -84px;

    &.headerSticky {
        top: 0;
        @include boxs (0px 4px 6px 0px rgba(12, 0, 46, 0.06));

    }

    .navbar {
        padding: 0;

        .logo {
            img {}
        }

        .navbar-toggler {
            width   : 30px;
            height  : 20px;
            position: relative;
            padding : 0;
            border  : none;

            .bar {
                position  : absolute;
                left      : 0;
                width     : 100%;
                height    : 2px;
                background: $wColor;
                display   : block;
                @include ts (all 0.5s);

                &:nth-child(1) {
                    top: 50%;
                    @include tf (rotate(45deg));
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    top: 50%;
                    @include tf (rotate(-45deg));
                }
            }

            &.collapsed {
                .bar {
                    @include tf (none);

                    &:nth-child(1) {
                        top: 0;
                    }

                    &:nth-child(2) {
                        top    : 50%;
                        opacity: 1;
                    }

                    &:nth-child(3) {
                        top: 100%;
                    }
                }
            }
        }


        .mainMenu {
            .navbar-nav {
                align-items: center;

                .nav-item {
                    margin-right: 25px;

                    &:last-child {
                        margin-right: 0;
                    }

                    .searchForm {
                        position: relative;

                        .searchInput {
                            border    : 1px solid $wColor;
                            background: rgba(255, 255, 255, 0.3);
                            height    : 32px;
                            @include br (16px);
                            @include boxs (none);
                            outline    : none;
                            padding    : 0 35px 0 15px;
                            max-width  : 220px;
                            width      : 100%;
                            color      : $wColor;
                            font-family: $open;
                            font-size  : $p;
                            line-height: 25px;
                            font-weight: 400;
                        }

                        .btnSearch {
                            position       : absolute;
                            background     : transparent;
                            border         : none;
                            color          : $wColor;
                            right          : 0px;
                            top            : 0;
                            width          : 40px;
                            height         : 100%;
                            display        : flex;
                            align-items    : center;
                            justify-content: center;
                            padding        : 0;
                            @include boxs (none);
                            outline: none;
                        }
                    }

                    .nav-link {
                        display    : inline-block;
                        color      : $wColor;
                        font-size  : $menuFont;
                        line-height: 20px;
                        font-family: $pnr;
                        padding    : 0;
                        position   : relative;

                        &:before {
                            position  : absolute;
                            content   : "";
                            width     : 0;
                            height    : 1px;
                            bottom    : 0;
                            right     : 0;
                            background: $wColor;
                            @include op(0);
                            @include ts (all 0.5s);
                        }

                        &.joinBtn {
                            background: $wColor;
                            border    : 1px solid $wColor;
                            color     : $brandColor2;
                            padding   : 6px 20px;
                            @include br (17px);

                            &:before {
                                display: none;
                            }

                            &:hover {
                                background: transparent;
                                color     : $wColor;
                            }
                        }

                        &:hover,
                        &.active {
                            &:before {
                                @include op(1);
                                left : 0;
                                right: auto;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .afterLogin {
        .dropdown-toggle {
            background: transparent;
            border    : none;
            @include boxs (none !important);
            outline: none;
            padding: 0;

            &:focus,
            &:active,
            :active:focus {
                background: transparent;
            }

            &:after {
                display: none;
            }

            .userIcon {
                .userImd {
                    img {
                        width : 30px;
                        height: 30px;
                        border: 1px solid $borderColor;
                        @include br (50%);
                        @include boxs (0px 6px 19.32px 1.68px rgba(52, 49, 49, 0.28));
                    }
                }
            }
        }

        .dropdown-menu {
            padding: 20px;
            border : none;
            width  : 285px;
            @include br (0 0 25px 25px);
            top  : 55px !important;
            left : auto !important;
            right: 0;
            @include boxs (0px 2px 10px 0px rgba(0, 0, 0, 0.2));

            .userProfile {
                display       : flex;
                border-bottom : 1px solid $borderColor;
                padding-bottom: 15px;
                margin-bottom : 22px;

                .useImg {
                    margin-right: 25px;

                    img {
                        width : 56px;
                        height: 56px;
                        border: 1px solid $borderColor;
                        @include br (50%);
                    }
                }

                .userInfo {
                    padding-top: 10px;

                    .userName {
                        font-family: $pnr;
                        font-size  : $f16;
                        color      : $dark;
                    }

                    .userPhone {
                        font-family: $pnr;
                        font-size  : $f13;
                        color      : #6b6666;
                    }
                }
            }

            .dropdownMenuList {
                margin    : 0;
                padding   : 0;
                list-style: none;

                li {
                    margin-bottom: 28px;

                    &:last-child {
                        margin-bottom: 15px;
                    }

                    a {
                        text-decoration: none;
                        color          : #4e4e4e;
                        font-size      : $f16;
                        display        : block;
                        @include ts (all 0.5s);

                        .text {
                            @include ts (all 0.5s);
                        }

                        .note {
                            background     : #ff7109;
                            color          : $wColor;
                            font-size      : 8px;
                            display        : flex;
                            align-items    : center;
                            justify-content: center;
                            width          : 15px;
                            height         : 15px;
                            @include br (50%);
                        }

                        span {
                            svg {}
                        }

                        &:hover {
                            color: $brandColor1;

                            .text {
                                padding-left: 3px;
                                color       : $brandColor1;
                            }
                        }
                    }
                }
            }
        }
    }
}


// header style