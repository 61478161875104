.footerWrap {
    background: url(../../../static/img/footer/footer_bg.png) no-repeat scroll top left / cover;

    .footerTop {
        padding: 95px 0 80px;
    }

    .footerSingleItem {
        .footerLogoWrap {
            max-width : 280px;
            width     : 100%;

            .footerLogo {
                margin-bottom: 15px;

                a {
                    display: inline-block;

                    img {
                        max-width: 140px;
                        width    : 100%;
                    }
                }
            }

            .footerdetails {
                .footerPara {}
            }
        }
    }

    .footerPara {
        color      : $wColor;
        font-size  : $f14;
        font-family: $open;
        line-height: 21px;
    }

    .footerTitle {
        color        : $wColor;
        font-family  : $pnb;
        font-size    : $f20;
        line-height  : 28px;
        margin-bottom: 20px;
    }

    .footerMenu {
        margin    : 0;
        padding   : 0;
        list-style: none;

        li {
            a {
                color      : $wColor;
                font-size  : $f14;
                font-family: $open;
                line-height: 33px;
                position   : relative;
                display    : inline-block;

                &::before {
                    position: absolute;
                    content : "-";
                    top     : 0;
                    left    : 5px;
                    @include ts (all 0.5s);
                    @include op (0);
                }

                &:hover {
                    padding-left: 8px;

                    &::before {
                        left: 0;
                        @include op (1);
                    }
                }
            }
        }
    }

    .footerdownloadApp {
        margin-left: -30px;

        .footerPara {
            font-size    : $f13;
            margin-bottom: 25px;
        }

        .appBtnGroup {
            .appBtn {
                svg {
                    fill : $wColor;
                    width: 100px;
                }
            }
        }
    }

    .footerBottom {
        background: $fCopy;
        padding   : 25px 0;

        .footerCopyRight {
            .copy {
                color      : $wColor;
                font-size  : $f12;
                font-family: $open;
                line-height: 21px;

                a {
                    color: $wColor;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .footerSocial {

            .solialLink {
                display        : flex;
                align-items    : center;
                justify-content: flex-end;

                span {
                    color      : $wColor;
                    font-size  : $f12;
                    font-family: $open;
                    line-height: 21px;
                    display    : flex;
                    align-items: center;

                    a {
                        display        : inline-block;
                        width          : 30px;
                        height         : 30px;
                        border         : 1px solid $wColor;
                        display        : flex;
                        align-items    : center;
                        justify-content: center;
                        @include br (7px);
                        margin-left: 9px;

                        &:hover {
                            background: $wColor;
                            color     : $brandColor1;
                        }
                    }
                }
            }
        }
    }
}