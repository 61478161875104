@charset "utf-8";
//-----Color class style

html {
    scroll-behavior     : smooth;
    overflow-y          : scroll;
    scroll-snap-type    : mandatory;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type    : y mandatory;
}

body {
    font-family               : $open;
    font-size                 : $p;
    line-height               : 25px;
    font-weight               : 400;
    color                     : $dark;
    -webkit-overflow-scrolling: touch;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

p {
    font-family: $open;
    font-size  : $p;
    line-height: 25px;
    font-weight: 400;
    color      : $dark;
}

a {
    text-decoration: $a-text-decuration;
    color          : $a-color;
    transition     : all 0.3s;
}

.underline {
    text-decoration: underline !important;
}

.hover-color:hover {
    color: $brandColor1;
}

a:hover {
    color          : $brandColor1;
    text-decoration: $a-text-decuration;
}

a:focus {
    outline: none !important;
}

a.link {
    color: $brandColor1;
}

a.link:hover {
    color: $brandColor1 !important;
}

.btn,
button,
button:focus {
    @include boxs (none !important);
    outline: none;
}

.container {
    // max-width: 1170px;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    width: 100%;
}

.joinBox {
    max-width: 350px;
    margin   : 0 auto 50px;

    .joinForm {
        width: 100%;
        @include br (27px);
        background     : $wColor;
        display        : flex;
        align-items    : center;
        justify-content: center;
        overflow       : hidden;
        @include boxs (0px 2px 10px 0px rgba(0, 0, 0, 0.2));

        .selectCountryCode {
            border: none;
            @include boxs (none);
            outline: none;
            color  : $brandColor1;
            padding: 0 15px 0 20px;
        }

        .joinInputBox {
            width      : 100%;
            display    : flex;
            align-items: center;
            position   : relative;

            .joinInput {
                border     : none;
                border-left: 1px solid #ccc;
                @include boxs (none);
                outline      : none;
                padding      : 0 15px;
                padding-right: 135px;
                font-size    : $f14;
                font-family  : $open;
                width        : 100%;
                height       : 54px;
                @include plc ($placeHolderColor);
            }

            .error {
                position   : absolute;
                bottom     : 0px;
                left       : 10px;
                font-size  : $f12;
                line-height: 15px;
            }

            .joinBtn {
                border     : none;
                width      : 100%;
                max-width  : 120px;
                padding    : 0;
                background : $brandColor1;
                font-size  : $f12;
                font-family: $open;
                color      : $wColor;
                height     : 40px;
                @include br (20px);
                position: absolute;
                right   : 8px;
                top     : 50%;
                @include tf (translateY(-50%));
                @include boxs (none);
                outline: none;
                @include ts (all 0.5s);

                &:hover {
                    background: #06a25f;
                }
            }
        }
    }
}

.secTopTitle {
    font-family: $open;
    font-size  : $f16;
    line-height: 25px;
    color      : $brandColor1;
}

.secTitle {
    font-family: $pnb;
    font-size  : $f38;
    line-height: 60px;
    color      : $bColor;
}

.secTitle2 {
    font-family: $pnr;
    font-size  : $f34;
    line-height: 70px;
    color      : $dark;
}

.mbHide {
    @media (max-width: 767px) {
        display: none;
    }
}

.loadMore {
    margin-bottom: 70px;

    .btn {
        max-width      : 200px;
        width          : 100%;
        margin         : 0 auto;
        height         : 50px;
        display        : flex;
        align-items    : center;
        justify-content: center;
        border         : none;
        color          : $wColor;
        background     : $brandColor1;
        @include br (25px);
        @include ts (all 0.5s);
        cursor: pointer;

        &:hover {
            @include boxs (0px 10px 30px 0px rgba(23, 198, 122, 0.5) !important);
        }
    }
}



.videoPlayer {
    #player {
        .bmpui-ui-watermark {
            background    : url(../../static/img/brand_logo.png) no-repeat;
            pointer-events: none;
        }

        .bitmovinplayer-poster {
            background-size: cover;
        }

        .bmpui-ui-hugeplaybacktogglebutton {

            .bmpui-image {
                background: url(../../static/img/play_icon.png) no-repeat center center;
                z-index   : 1;
                position  : relative;
            }
        }

        .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition-marker,
        .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker {
            background-color: rgba(23, 198, 122, 0.5);
            border          : 3px solid $brandColor1;
        }

        .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition,
        .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition,
        .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker {
            background: $brandColor1;
        }
    }
}


.onlyForAudio {
    .videoPlayer #player .bmpui-ui-hugeplaybacktogglebutton.bmpui-off::before {
        animation-iteration-count: 0;
        background-position      : 0 0;
    }

    .videoPlayer #player .bmpui-ui-hugeplaybacktogglebutton::before {
        position: absolute;
        content : "";
        top     : 0;
        left    : 0;
        width   : 820px;
        height  : 100%;
        @include ts(all 0.5s);
        background: rgba(0, 0, 0, 0) url(https://ltech.sgp1.cdn.digitaloceanspaces.com/iqra-live.com/web_assets/musicBg-1587195975-5411126.jpg) repeat-x scroll center center/100% 600px;
        animation : audioMusicBackground linear 25s infinite running;
        opacity   : 1;
    }
}


pre {
    word-break : break-all;
    display    : block;
    width      : 100%;
    height     : auto;
    overflow   : inherit;
    white-space: normal;
    font-family: "Open Sans", "sans-serif";
    font-size  : 16px;
    line-height: 30px;
    font-weight: 400;
    color      : #333434;
}

@-webkit-keyframes audioMusicBackground {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 820px 0;
    }
}

@keyframes audioMusicBackground {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 820px 0;
    }
}

.ramadanCalendarTable {
    tr {
        &.active {
            color: $wColor !important;
            background-color: $brandColor1 !important;
        }
    }
}


.ramadanForAirtel {
    .border {
        height: 50px;
        width : 100%;
        @include bgGradient ($brandColor1, $brandColor2);
    }
    .digitalQuarnItem {
        padding: 40px 0;
    }

}
.hadithTop {
    margin-bottom: 20px;
    position: sticky;
    padding-bottom: 10px;
    top: 0;
    background: $hadithBg;
    display: flex;
    align-items: center;
    p {
        font-size: $f20;
    }
}
.goBack {
    display: none;
}