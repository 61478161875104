.myCoursesWrap {
    .digitalQuarnItem {
        padding-bottom: 105px;

        .featuredTop {
            margin-bottom: 0;

            .secTitle2 {
                font-size  : $f30;
                line-height: 36px;
            }

            .subTitle {
                color    : $brandColor2;
                font-size: $f18;
            }
        }
    }
}

.myCourseTableWrap {
    padding: 0 30px;

    .table-responsive {
        .table {
            tr {
                &:first-child {
                    td {
                        border: none;
                    }
                }

                &:last-child {
                    td {
                        border-bottom: 1px solid #e1e1e1;
                    }
                }

                @include ts (all 0.5s);

                &:hover {
                    // @include boxs (0px 0px 10px rgba(95, 95, 95, 0.3));
                    background: $grayLightBg;

                    td {
                        border-color: $grayLightBg;
                    }

                    &+tr {
                        td {
                            border-color: $grayLightBg;
                        }
                    }
                }

                td {
                    border    : none;
                    border-top: 1px solid #e1e1e1;
                    padding   : 70px 20px 30px;
                    position  : relative;
                    @include ts (all 0.5s);

                    &:nth-child(1) {
                        width: 250px;
                    }

                    &:nth-child(2) {
                        padding-right: 120px;
                    }

                    &:nth-child(3) {
                        vertical-align: bottom;
                        width         : 200px;
                    }

                    .myCoursesDetails {
                        .courseName {
                            font-family  : $pnr;
                            font-size    : $f30;
                            line-height  : 36px;
                            color        : $brandColor2;
                            margin-bottom: 7px;
                        }

                        .courseBy {
                            font-size    : $f18;
                            line-height  : 24px;
                            color        : #10c0c8;
                            margin-bottom: 10px;
                        }

                        .courseDuration {
                            font-size    : $f14;
                            line-height  : 20px;
                            color        : #09b78f;
                            margin-bottom: 20px;

                            .qa {
                                margin-left : 30px;
                                padding-left: 38px;
                                position    : relative;

                                &::before {
                                    position  : absolute;
                                    content   : "";
                                    width     : 8px;
                                    height    : 8px;
                                    background: #09b78f;
                                    @include br (50%);
                                    top: 50%;
                                    @include tf (translateY(-50%));
                                    left: 0;
                                }
                            }

                            .duration {}
                        }

                        .courseTags {
                            display: flex;

                            .courseTag {
                                font-size      : 14px;
                                line-height    : 20px;
                                color          : #4c4c4c;
                                border         : 1px solid #d2d2d2;
                                height         : 24px;
                                display        : flex;
                                align-items    : center;
                                justify-content: center;
                                @include br (12px);
                                padding     : 0 17px;
                                margin-right: 15px;
                                @include ts (all 0.5s);

                                &:hover {
                                    background: #d2d2d2;
                                }
                            }
                        }
                    }

                    .myCourseProfile {
                        position: absolute;
                        right   : 20px;
                        top     : 70px;

                        svg {
                            width : 55px;
                            height: 55px;
                            @include br (50%);
                        }
                    }

                    .progressFull {
                        display           : flex;
                        // justify-content: flex-end;

                        .btnFullProgress {
                            display        : flex;
                            align-items    : center;
                            justify-content: center;
                            width          : 120px;
                            height         : 40px;
                            @include br (20px);
                            background: $brandColor2;
                            color     : $wColor;
                            font-size : 14px;
                            @include ts (all 0.5s);
                            margin-right: 20px;

                            &:hover {
                                background: $brandColor1;
                            }

                            &+.btnFullProgress {
                                background: $brandColor1;

                                &:hover {
                                    background: $brandColor2;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.courseProgress {
    margin-bottom: 40px;

    .progressCount {
        color    : #4c4c4c;
        font-size: 14px;

        .progressTitle {
            color: #000;
        }
    }

    .progress {
        height: 7px;
        @include br (10px);
        background: #e1e1e1;

        .progress-bar {
            background: $brandColor1;
            @include br (10px);
        }
    }

    &.completeClasses {
        .progressCount {
            .progressTitle {
                color: #10c0c8;
            }
        }

        .progress {
            .progress-bar {
                background: $brandColor2;
            }
        }
    }

    &.remainingClasses {
        .progressCount {
            .progressTitle {
                color: #10c0c8;
            }
        }

        .progress {
            .progress-bar {
                background: #f74588;
            }
        }
    }
}



.myCourseLogo {

    img {
        max-width: 170px;
        width    : 100%;
        height: 170px;
        @include br (50%);
        @include boxs (0px 0px 20px 0px rgba(95, 95, 95, 0.4));
        margin-bottom: 15px;
    }

    .poweredByLogo {
        display: inline-block;

        p {
            color    : $dark;
            font-size: $f18;
        }
    }
}

.courseDetailsSection {
    .profileImg {
        padding: 20px 0 25px;

        svg {}
    }

    .courseName {
        font-family  : $pnr;
        font-size    : $f30;
        color        : $brandColor2;
        margin-bottom: 20px;

        b {
            font-family: $pnb;
            font-weight: normal;
        }
    }

    .courseInstructor {
        color        : #10c0c8;
        font-size    : $f20;
        margin-bottom: 30px;

        b {
            font-weight: 600;
        }
    }


    .userProfilePic {
        img {
            @include boxs (0px 9px 27px 0px rgba(56, 60, 59, 0.15));
            border       : 1px solid $borderColor;
            width        : 95px;
            height       : 95px;
            margin-bottom: 30px;
        }
    }

    .enrollInfo {
        .enrollInfoTable {
            max-width: 350px;

            tr {
                td {
                    border   : none;
                    color    : $dark;
                    font-size: $f16;

                    b {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .availableWeek {
        margin-bottom: 30px;

        p {
            color        : $dark;
            font-size    : $f18;
            margin-bottom: 25px;
        }

        .availableWeekList {
            margin     : 0;
            padding    : 0;
            list-style : none;
            display    : flex;
            align-items: center;

            li {
                width          : 60px;
                height         : 60px;
                display        : flex;
                align-items    : center;
                justify-content: center;
                @include br (50%);
                background  : #e1e1e1;
                margin-right: 30px;
                color       : $dark;
                font-size   : $f16;

                &:last-child {
                    margin-right: 0;
                }

                &.active {
                    background: $brandColor1;
                    color     : $wColor;
                }
            }
        }
    }

    .enrollProgress {
        padding: 5px 0;
    }
}