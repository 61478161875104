.LiveInstructorList {
    .FeaturedInnerItem {
        padding-bottom: 65px;
    }
}

.LiveInstactorItem {
    margin-bottom: 35px;
}

.LiveInstactorSingleItem {
    display   : inline-block;
    background: $wColor;
    @include br (35px);
    @include boxs (0 0 10px 10px rgba(0, 0, 0, 0.05));
    padding: 25px;
    @include ts (all 0.5s);
    height  : 100%;
    width   : 100%;
    position: relative;

    .itemImg {
        margin-bottom: 33px;

        img {
            width : 100px;
            height: 100px;
            @include br (50%);
            border: 1px solid $borderColor;
        }
    }



    .cont {
        margin-bottom: 18px;

        .teacherName {
            color    : $brandColor2;
            font-size: $f24;
            line-height: 36px;
            @include ts (all 0.5s);
        }

        .discripTion {
            color: $darkLight;
            @include ts (all 0.5s);
        }
    }

    .btnGroup {
        .btn {
            color       : $wColor;
            font-size   : $f13;
            height      : 40px;
            line-height : 40px;
            padding     : 0 30px;
            background  : $brandColor1;
            margin-right: 15px;
            @include br (16px);

            &+.btn {
                background: $brandColor2;
            }
        }
    }

    .countDownTime {
        margin-right: -10px;

        p {
            span {
                width         : 40px;
                display       : inline-block;
                background    : $brandColor1;
                padding       : 3px 5px;
                color         : $wColor;
                text-align    : center;
                border-radius : 7px;
                letter-spacing: 2px;
            }
        }
    }

    &:hover {
        background: $brandColor1;

        .cont {
            .teacherName {
                color: $wColor;
            }

            .discripTion {
                color: $wColor;
            }

            .btnGroup {
                .btn {
                    background: $wColor;
                    color     : $darkLight;
                }
            }
        }

        .btnGroup {
            .btn {
                background: $wColor;
                color     : $dark;
            }
        }

        .countDownTime {

            p {
                color: $wColor;
                span {
                    background: $wColor;
                    color: $brandColor1;
                }
            }
        }
    }
}

.liveRight {
    position: absolute;
    right   : 30px;
    top     : 20px;

    .liveNow {
        .live_icon {
            width : 35px;
            height: 35px;
            border: 1px solid #fc1f1f;
            @include br (50%);
            display        : flex;
            align-items    : center;
            justify-content: center;
            background     : $wColor;
            position       : relative;

            &::before {
                position  : absolute;
                content   : "";
                top       : 0;
                left      : 0;
                width     : 100%;
                height    : 100%;
                background: #fc1f1f;
                border    : 1px solid #fc1f1f;
                @include ts (all 0.5s);
                animation: live 1.5s linear infinite;
                @include br (50%);
                z-index: 1;
            }

            &::after {
                position  : absolute;
                content   : "";
                top       : 0;
                left      : 0;
                width     : 100%;
                height    : 100%;
                background: #fc1f1f;
                border    : 1px solid #fc1f1f;
                @include ts (all 0.5s);
                animation: live 2.5s linear infinite;
                @include br (50%);
                z-index: 1;
            }

            .inner {
                display        : flex;
                align-items    : center;
                justify-content: center;
                background     : transparent;
                width          : 25px;
                height         : 25px;
                border         : 6px solid #fc1f1f;
                @include br (50%);
                position : relative;
                animation: live 2.75s linear infinite;

            }
        }

        .live {
            color     : #fc1f1f;
            font-size : $f13;
            text-align: center;
        }
    }
}



@keyframes live {
    0% {
        opacity  : 1;
        transform: scale(0);
    }

    100% {
        opacity  : 0;
        transform: scale(2);
    }
}