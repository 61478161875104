

@font-face {
    font-family: 'proxima_novabold';
    src: url('proxima_nova_bold-webfont.woff2') format('woff2'),
         url('proxima_nova_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proxima_novaregular';
    src: url('proximanova-regular-webfont.woff2') format('woff2'),
         url('proximanova-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}